import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home";

import AdminDashboard from "./pages/Admin";
import PrivateRoute from "./pages/Auth/PrivateRoute";
import Login from "./pages/Auth/components/Login";
import Register from "./pages/Auth/components/Register";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/admin"
          element={<PrivateRoute component={AdminDashboard} />}
        />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}
