import { useState } from 'react';

function usePostData(postFunction) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const postData = async (postData) => {
    setLoading(true);
    setError(null);
    
    try {
      const result = await postFunction(postData);
      setData(result);
      return result;
    } catch (error) {
      setError(error.toString());
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, postData };
}

export default usePostData;