import axios from "axios";
import { RouteAPI } from "../../services/constants";

function AuthService() {
  const login = async (userData) => {
    try {
      const res = await axios.post(RouteAPI.LOGIN_API, userData);
      return res.data;
    } catch (error) {
      console.error("Login error:", error);
      throw error.response?.data?.message || "An error occurred during login";
    }
  };

  const register = async (userData) => {
    try {
      const res = await axios.post(RouteAPI.REGISTER_API, userData);
      return res.data;
    } catch (error) {
      console.error("Registration error:", error);
      throw (
        error.response?.data?.message || "An error occurred during registration"
      );
    }
  };

  return { login, register };
}

export default AuthService;
