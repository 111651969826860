import React, { useState } from "react";
import useFetchData from "../../hooks/useFetch";
import { RouteAPI } from "../../services/constants";
import {
  DownOutlined,
  RightOutlined,
  SwapLeftOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";

import "./style.scss";
export default function Sidebar({ onPageSelect }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [expandedItems, setExpandedItems] = useState({});

  const { data, error, loading } = useFetchData(RouteAPI.PAGE_API);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const toggleSidebar = () => {
    console.log("toggleSidebar called");
    setIsSidebarOpen((prevState) => !prevState);
    console.log("Toggle sidebar, new state:", !isSidebarOpen);
  };

  const handleItemClick = (page, isToggleClick = false) => {
    if (!isToggleClick) {
      onPageSelect(page.slug);
    }

    if (page.children && page.children.length > 0) {
      setExpandedItems((prevState) => ({
        ...prevState,
        [page._id]: !prevState[page._id],
      }));
    }
  };

  const renderPages = (pagesArray, level = 0) => {
    return (
      <ul className={`level-${level}`}>
        {pagesArray.map((page) => {
          const hasChildren = page.children && page.children.length > 0;
          const isExpanded = expandedItems[page._id];

          return (
            <li key={page._id}>
              <div
                className="sidebar-item"
                style={{
                  boxSizing: "border-box",
                  borderLeft: "1px solid white",
                  paddingLeft: "10px",
                }}
              >
                <button
                  type="button"
                  // onClick={() => handleItemClick(page)}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleItemClick(page);
                  }}
                  className={`level-${level} toggle-btn`}
                >
                  {/* <i
                    className={`ai-${
                      level === 0 ? "dashboard" : "sub-menu-icon"
                    }`}
                  /> */}
                  <p>
                    {"\u00A0".repeat(level * 2)}
                    {page.title}
                  </p>
                  {hasChildren && (
                    <span
                      className={`submenu-icon ${isExpanded ? "expanded" : ""}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleItemClick(page, true);
                      }}
                    >
                      {isExpanded ? <DownOutlined /> : <RightOutlined />}
                    </span>
                  )}
                </button>
                {/* {hasChildren && (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleItemClick(page, true);
                    }}
                    className="toggle-btn"
                  >
                    <i
                      className={`submenu-icon ${isExpanded ? "expanded" : ""}`}
                    />
                  </button>
                )} */}
              </div>
              {hasChildren &&
                isExpanded &&
                renderPages(page.children, level + 1)}
            </li>
          );
        })}
      </ul>
    );
  };

  // Function to handle expand/collapse of individual level 0 items

  return (
    <>
      <aside className={`sidebar1 ${isSidebarOpen ? "" : "hidden"}`}>
        <div className="sidebar-container">
          <header>
            <button
              type="button"
              className="sidebar-burger"
              onClick={toggleSidebar}
            >
              <SwapLeftOutlined />
            </button>
            <img src="logo.svg" alt="Logo" />
          </header>
          {renderPages(data)}
        </div>
      </aside>
      {!isSidebarOpen && (
        <button
          type="button"
          className="sidebar-burger show-burger"
          onClick={toggleSidebar}
          style={{
            position: "fixed",
            top: "24px",
            left: "24px",
            background: "rgba(0, 0, 0, 0.4)",
            border: "none",
            borderRadius: "8px",
            padding: "10px",
          }}
        >
          <SwapRightOutlined />
        </button>
      )}
    </>
  );
}
