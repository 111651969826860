// export const API_ENV = 'https://linhho.annk.info/api';
export const API_ENV = "https://zonevisa.annk.info/api";

export const RouteAPI = {
  LOGIN_API: `${API_ENV}/auth/login`,
  REGISTER_API: `${API_ENV}/auth/register`,

  INFORMATION_API: `${API_ENV}/info/`,
  INFORMATION_ID_API: `${API_ENV}/info/:id`,

  PAGE_API: `${API_ENV}/pages/`,
  PAGE_ID_API: `${API_ENV}/pages/:id`,
  PAGE_CONTENT_API: `${API_ENV}/pages/slug/:slug`,
};
