import React, { useState } from "react";
import Content from "../../components/content";
import Sidebar from "../../components/sidebar";
import './style.scss'
export default function Home() {
  const [selectedSlug, setSelectedSlug] = useState("business");
  const handlePageSelect = (slug) => {
    setSelectedSlug(slug);
  };

  return (
    <div className="layout" >
      <Sidebar onPageSelect={handlePageSelect} />
      <Content slug={selectedSlug} />
    </div>
  );
}
