import axios from "axios";
import { RouteAPI } from "./constants";

function AppService() {
  const getInfo = async () => {
    const res = await axios.get(RouteAPI.INFORMATION_API);
    return res.data;
  };

  const postInfo = async () => {
    try {
      const res = await axios.post(RouteAPI.INFORMATION_API, {});
      return res.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const getPages = async () => {
    const res = await axios.get(RouteAPI.PAGE_API);
    return res.data;
  };

  const getPageContent = async (slug) => {
    const res = await axios.get(
      RouteAPI.PAGE_CONTENT_API.replace(":slug", slug)
    );
    return res.data;
  };

  const login = async (userData) => {
    try {
      const res = await axios.post(RouteAPI.LOGIN_API, userData);
      return res.data;
    } catch (error) {
      console.error("Login error:", error);
      throw error;
    }
  };

  const register = async (userData) => {
    try {
      const res = await axios.post(RouteAPI.REGISTER_API, userData);
      return res.data;
    } catch (error) {
      console.error("Registration error:", error);
      throw error;
    }
  };

  const createMainPages = async (userData) => {
    try {
      const token = localStorage.getItem("token");

      const res = await axios.post(RouteAPI.PAGE_API, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  return {
    getInfo,
    postInfo,
    getPages,
    login,
    register,
    getPageContent,
    createMainPages,
  };
}

export default AppService;
