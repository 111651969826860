import React, { useEffect, useState } from "react";
import useFetchData from "../../hooks/useFetch";
import { RouteAPI } from "../../services/constants";
import ReactMarkdown from "react-markdown";

import "./style.scss";
import remarkGfm from "remark-gfm";
import remarkParse from "remark-parse"; // Importing remark-parse
import { unified } from "unified"; // Importing unified
export default function Content({ slug }) {
  const [pageContent, setPageContent] = useState(null);

  const url = RouteAPI.PAGE_CONTENT_API.replace(":slug", slug);
  const { data, error, loading, refetch } = useFetchData(url);

  useEffect(() => {
    refetch();
  }, [slug, refetch]);

  useEffect(() => {
    if (data) {
      setPageContent(data);
    }
  }, [data]);

  if (loading) return <div>Loading...</div>;
  if (error)
    return <div>Error: {error.message || "An unknown error occurred"}</div>;

  console.log(pageContent);

  // const fetchPageContent = async (pageSlug) => {
  //   try {
  //     const response = await axios.get(`https://zonevisa.annk.info/api/pages/slug/${pageSlug}`);
  //     setPageContent(response.data);
  //   } catch (error) {
  //     console.error("Error fetching page content:", error);
  //   }
  // };

  const extractHeadings = (content) => {
    const headings = [];

    const markdownAST = unified()
      .use(remarkParse) // Use remark-parse to parse Markdown
      .use(remarkGfm) // Use remark-gfm for GitHub Flavored Markdown
      .parse(content);

    const visit = (node) => {
      if (node.type === "heading") {
        headings.push({
          level: node.depth,
          text: node.children.map((child) => child.value).join(""),
        });
      }
      if (node.children) {
        node.children.forEach(visit);
      }
    };

    visit(markdownAST);
    return headings;
  };

  return (
    <div className="content">
      {/* <div className="layout"></div> */}
      <main>
        <header>
          <h1>{pageContent?.title}</h1>
        </header>
        <div className="page-content">
          {/* {pageContent?.content} */}
          <ReactMarkdown>{pageContent?.content}</ReactMarkdown>
        </div>
      </main>
      <aside>
        <h2>Table of Contents</h2>
        <ul>
          {extractHeadings(pageContent?.content).map((heading) => (
            <li key={heading.text}>
              <a href={`#${heading.text.toLowerCase().replace(/\s+/g, "-")}`}>
                {heading.text}
              </a>
            </li>
          ))}
        </ul>
      </aside>
    </div>
  );
}
